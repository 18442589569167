body {
  margin: 0;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  overflow-y: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol {
  margin: 0;
  padding-left: 40px;
  outline: none;
}

li {
  padding: 5.5px 0 5.5px 3px;
  line-height: 1.6em;
}

p {
  line-height: 1.6em;
  outline: none;
}

h1, h2, h3 {
  padding: 0.6em 0 3px;
  margin: 0;
  line-height: 1.25em;
  outline: none;
}

.zoomable {
  transition: transform 0.3s ease;
}

.zoomable:hover {
  transform: scale(0.9);
}

.custom-code {
  color: #41314e;
  line-height: 1.6em;
  background: #f8f7fa;
  border: 1px solid #f1f1f4;
  box-shadow: none;
  white-space: pre;
  word-wrap: normal;
  overflow-x: auto;
  padding: 10px;
  border-radius: 10px;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace !important;
}
.ce-code__textarea {
  color: #41314e !important;
  line-height: 1.6em !important;
  background: #f8f7fa !important;
  border: 1px solid #f1f1f4 !important;
  box-shadow: none !important;
  white-space: pre !important;
  word-wrap: normal !important;
  overflow-x: auto !important;
  padding: 10px !important;
  border-radius: 10px !important;
  font-size: 20px !important;
}


a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

@keyframes rotateXAnimation {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(15deg); /* Rotate to 30 degrees at 50% of the animation */
  }
  100% {
    transform: rotateX(0deg); /* Return to the initial position at the end of the animation */
  }
}

.rotateX {
  animation: rotateXAnimation 7s infinite; /* Use the ease-in-out timing function */
  transition-timing-function: ease-in-out; /* Add a transition timing function for smooth transition */
  transition-property: transform; /* Apply the transition to the transform property only */
}

.card-container:hover .rotate-on-hover {
  cursor: pointer;
  transform: rotate(360deg); /* Add the rotation animation on hover */
}
.card-container {
  height: 100%;
}

.MuiContainer-maxWidthMd {
  max-width: 780px !important;
}